#home {
    display: block;
    transform: translateY(-50%);
    margin-top: calc(50vh - 30px);
    text-align: center;
}
#home-loading {
    width: 150px;
    height: 150px;
    background-size: 100%;
    background-image: url('../images/loading.svg');
    background-repeat: no-repeat;
    margin: 0 auto;
}
#home h2 {
    font-size: 28px;
    margin: 20px auto;
}
.home-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-social .nickname {
    margin-top: 10px;
    font-size: 14px;
}
.home-social .nickname a {
    color: #E9002D;
    font-weight: bold;
}
.home-social .st-button {
    margin: 5px auto;
}
.st-button.social {
    display: flex;
    align-items: center;
    padding: 0;
    overflow: hidden;
}
.st-button.social i {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 20px 25px 20px 20px;
}
.st-button.social span {
    padding: 0 10px;
}
.st-button.twitter {
    background-color: #1DA1F2;
}
.st-button.twitter:hover {
    background-color: #0576bd;
}
.st-button.discord {
    background-color: #5865f2;
}
.st-button.discord:hover {
    background-color: #404cc5;
}
i.icon {
    content: '';
    width: 26px;
    height: 26px;
    background-position: 60% 60%;
    background-repeat: no-repeat;
    background-size: 55%;
}
i.icon.discord {
    background-image: url('../images/discord.svg');
}
i.icon.twitter {
    background-image: url('../images/twitter.svg');
}
