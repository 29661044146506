#app-game {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    min-height: 100vh;
}
#contains-right {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    min-height: 100vh;
}
#game {
    width: 100%;
}
#players {
    width: 100%;
}
#contains-right {
    width: 100%;
}
@media screen and (min-width: 768px) {
    #app-game {
        display: flex;
        flex-direction: row;
    }
    #game {
        width: 50%;
    }
    #players {
        width: 50%;
    }
    #contains-right {
        width: 100%;
    }
}
@media screen and (min-width: 1280px) {
    #game {
        width: 50%;
    }
    #players {
        width: 25%;
    }
    #contains-right {
        width: 25%;
    }
}
.st-input {
    display: inline-block;
    box-sizing: border-box;
    background-color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    margin: 0px 2px;
    font-family: 'Poppins'
}
.st-button {
    display: inline-block;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 16px;
    height: 40px;

    background-color: #E9002D;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 12px 15px;
    margin: 0px 2px;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Poppins'
}
.st-button:hover {
    background-color: #b80025;
}
.st-textarea {
    border: none;
    border-radius: 4px;
    padding: 6px 8px;
    font-size: 14px;
    outline: none;
    margin: 0px 2px;
    resize: vertical;
    background-color: white;
    word-break: break-all;
}
.st-select {
    border: none;
    border-radius: 4px;
    padding: 6px 8px;
    font-size: 14px;
    outline: none;
    margin: 0px 2px;
    background-color: white;
    font-family: 'Poppins';
}
.st-input:disabled {
    background-color: #a8a8a8;
}
.st-button:disabled {
    background-color: #a8a8a8;
}
.st-textarea:disabled {
    background-color: #a8a8a8;
}
.st-select:disabled {
    background-color: #a8a8a8;
    opacity: 1;
}
.separator::after {
    content: "";
    display: block;
    width: 100px;
    height: 7px;
    border-radius: 10px;
    background-color: #E9002D;
    margin: 15px auto 25px;
}