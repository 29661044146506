.radio-check-container {
    display: inline-block;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 16px;

    background-color: #E9002D;
    border-radius: 20px;
    overflow: hidden;
}
.radio-check-container .radio-check-input {
    display: inline-block;
    color: #fff;
    padding: 12px 15px;
    cursor: pointer;
}
.radio-check-container .radio-check-input.active {
    background-color: rgba(0, 0, 0, 0.15);
}