#chat {
    display: flex;
    flex-direction: column;
    height: 80vh;
    background-color: rgb(30, 30, 35);
    color: white;
}
#chat-messages {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 20px;
    line-height: 20px;
    font-size: 14px;
    overflow-y: auto;
    word-break: break-word;
}
#chat-messages li {
    margin-bottom: 5px;
}
#chat-input input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    padding: 10px;
    font-size: 14px;
    outline: none;
}