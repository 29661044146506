#post-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    background-color: rgb(24, 24, 28);
    color: white;
    padding: 10px;
    height: 20vh;
    overflow-y: auto;
    word-break: break-word;
}
#post-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
}
#post-list ul li a {
    color: #E9002D;
}