#players {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: rgb(32, 32, 37);
    color: white;
    overflow-y: auto;
}
ul#list-players {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 0;
    list-style: none;
}
#list-players .player {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 70px;
    list-style: none;
    overflow: hidden;
    cursor: pointer;
}
#list-players .player .image {
    margin-left: 3px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
}
#list-players .player .text {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    text-decoration: none;
    color: white;
}
#list-players .player .artist {
    background-color: rgba(255, 255, 255, 0.03);
    padding: 2px 8px;
    overflow: hidden;
    border-radius: 20px;
    font-size: 14px;
    margin: 2px auto;
    max-width: 45%;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#list-players .player .score {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    width: 45px;
    height: 45px;
    border-radius: 50px;
    font-size: 18px;
    margin-right: 10px;
}
#list-players .player .artist-right {
    background: rgb(40, 189, 40);
}
#list-players .player .artist-wrong {
    background: rgb(190, 40, 40);
}
#list-players .player.player-won {
    background-color: rgb(8, 211, 25);
}