#game {
    display: flex;
    flex-direction: column;
    color: white;
}
#game-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
video {
    width: 100%;
    max-height: calc(100vh - 280px);
    outline: none;
}
#answers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
#answers.disabled {
    opacity: 0.5;
    pointer-events: none;
}
#answers.final {
    opacity: 1;
    pointer-events: none;
}

input.answer {
    border-radius: 50px;
    margin: 0 auto;
    outline: none;
    color: white;
    cursor: text;
}
input.answer::placeholder {
    color: #aaa;
}
input.answer:disabled {
    cursor: not-allowed;
}
.answer {
    width: calc(50% - 20px);
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
    border: none;
    padding: 20px;
    margin: 10px;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    text-transform: capitalize;
    font-size: 16px;
}
div.answer:not(.answer-chosen):not(.answer-wrong):not(.answer-correct):hover {
    background: rgba(255, 255, 255, 0.15);
}
div.answer-chosen {
    background: rgba(255, 255, 255, 0.4);
}
.answer-wrong {
    background: rgb(190, 40, 40)!important;
}
.answer-correct {
    background: rgb(40, 189, 40)!important;
}
.answer-text {
    display: inline-block;
    font-size: 12px;
    background: rgb(40, 189, 40);
    color: white;
    padding: 6px 10px;
    border-radius: 20px;
    margin: 10px auto 0 auto;
    width: fit-content;
}

#game-button-container {
    display: none;
}
#game-button {
    display: block;
    width: 200px;
    background-color: #E9002D;
    border-bottom: 7px solid #a80022;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 20px auto;
    cursor: pointer;
}
#answers:not(.disabled):not(.final) #game-button:hover {
    background-color: #E9002D;
    border-bottom: 6px solid #a80022;
    margin-top: 21px;
}
#game-button:active {
    background-color: #E9002D;
    border-bottom: 2px solid #a80022;
    margin-top: 25px;
}
#game-button.pressed {
    background-color: #3d3d3d;
    border-bottom: 2px solid #2b2b2b;
}
#game-percentage {
    display: block;
    width: 100%;
    height: 20px;
}
#game-percentage-bar {
    display: block;
    width: 100%;
    height: 20px;
    background-color: #E9002D;
    transition: width 0.1s ease;
}

#game-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
#game-popup-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}
#game-popup-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: rgb(30, 30, 35);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    z-index: 11;
}
#game-popup-header {
    background-color: #E9002D;
    padding: 10px;
}
#game-popup-settings {
    padding: 10px;
}
#game-popup-header h2 {
    margin: 0;
    font-size: 24px;
    color: white;
}
#game-popup-settings h3 {
    margin: 10px auto 5px auto;
    font-size: 16px;
    color: white;
}

#game-popup-close {
    position: absolute;
    top: 0px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.game-popup-setting {
    margin-bottom: 20px;
}
.game-popup-setting > * {
    width: 60%;
    margin: 0 auto;
}
#game-popup-footer {
    margin-top: 30px;
}
.st-button.game-popup-pool-button {
    width: auto;
    font-size: 10px;
    padding: 4px 5px;
    height: 23px;
    text-transform: uppercase;
}