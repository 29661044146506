#tos-content {
    padding: 10px 20% 30px;
    font-size: 14px;
}
#tos-content h2 {
    margin: 30px auto 15px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}
#tos-content p {
    text-align: justify;
}
#tos-content a {
    color: #e9002d;
}

@media screen and (max-width: 768px) {
    #tos-content {
        padding: 10px 5% 30px;
    }
}