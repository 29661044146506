#header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    background-color: #E9002D;
    color: white;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
#header-left {
    position: absolute;
    left: 10px;
}
#header-controls {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
}
#header-title h1 {
    margin: 0;
    font-size: 24px;
}