footer {
    display: block;
    box-sizing: border-box;
    background-color: #e9002d;
    color: white;
    padding: 10px;
}
footer ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 20px 10px;
}
footer ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
}
footer ul li::after {
    content: "|";
    padding: 0 30px;
}
footer ul li a:hover {
    text-decoration: underline;
}
footer ul li:last-child::after {
    content: "";
    padding: 0px;
}

@media screen and (max-width: 640px) {
    footer ul {
        flex-direction: column;
        align-items: center;
    }
    footer ul li a {
        font-size: 14px;
    }
    footer ul li::after {
        content: "";
        padding: 0px;
    }
}