#rooms {
    text-align: center;
    min-height: 100vh;
}
#rooms h2 {
    margin: 30px auto;
    font-size: 28px;
}

.rooms-list {
    padding: 0px 10px 12px;
}
.rooms-creator-container {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 10px 10px 42px;
}
.rooms-footer {
    padding: 0px 10px 12px;
}

.list-rooms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 20px 0;
    list-style: none;
}
.list-rooms li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 300px;
    height: 100%;
    margin: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    list-style: none;
    overflow: hidden;
    text-decoration: none;
}
.list-rooms li .name {
    flex: 1;
    text-decoration: none;
    color: white;
}
.list-rooms li .badge {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
}

.rooms-creator {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.rooms-creator > * {
    margin: 5px;
}