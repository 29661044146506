body {
    margin: 0;
    font-family: 'Poppins', 'Open Sans', 'Montserrat', 'Roboto', 'Verdana', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(35, 35, 40);
    color: white;
}
h1, h2, h3, h4, h5 {
    font-family: 'Montserrat';
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
