
#alert-message {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
#alert-message-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
}
#alert-message-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    min-width: 250px;
    background-color: rgb(30, 30, 35);
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    z-index: 21;
}
#alert-message-header {
    background-color: #E9002D;
    padding: 10px;
}
#alert-message-settings {
    padding: 10px;
}
#alert-message-header h2 {
    margin: 0;
    font-size: 24px;
    color: white;
}
#alert-message-settings h3 {
    margin: 10px auto 5px auto;
    font-size: 16px;
    color: white;
}

#alert-message-close {
    position: absolute;
    top: 0px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
#alert-message-footer {
    margin-top: 30px;
}