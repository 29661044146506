.players-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: rgb(15, 15, 20);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    width: 200px;
    border-radius: 5px;
    list-style: none;
    margin: -10px 0 0 0;
    padding: 5px;
    z-index: 2;
    outline: none;
}
.players-dropdown li {
    padding: 10px 15px;
    cursor: pointer;
    color: white;
    border-radius: 3px;
    font-size: 15px;
}
.players-dropdown li:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
}